import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  howItWorksBall: {
    width: theme.spacing(22),
    height: theme.spacing(22),
    boxShadow: '0px 10px 20px 0px #ccc',
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  gray: {
    color: '#888888'
  },
}))

export default function HowItWorksBall({line1, line2, line3, color = '#52c0f0'}) {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Box className={classes.howItWorksBall}>
      <Typography variant="h5" style={{color}}>{line1}</Typography>
      <Typography variant="body1">{line2}</Typography>
      <Typography variant="body2" className={classes.gray}>{line3}</Typography>
    </Box>
  )
}
