import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core"
import { useCompany } from "hooks/useCompany"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import LogoClean from "components/icons/LogoClean"
import Company from "components/icons/Company"
import Candidate from "components/icons/Candidate"
import HowItWorksBall from "./components/HowItWorksBall"
import Faq from "containers/company/components/Faq"
import Router from "next/router"
import SuccessButton from "components/Button/Success"
import ModalEmployee from "./components/ModalEmployee"
import RegisterCandidateProvider from "context/useRegisterCandidate"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    overflow: "hidden",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  rootProgress: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(8),
    display: 'inline-block',
  },
  boxInformation: {
    marginTop: theme.spacing(10),
    overflow: "hidden",
  },
  boxInformationText: {
    [theme.breakpoints.up("md")]: {
      paddingRight: `${theme.spacing(10)}px !important`,
    },
  },
  boxInformationButtons: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
      marginTop: 20,
    },
  },
  name: {
    marginBottom: theme.spacing(5),
    fontWeight: "bold",
    fontSize: 65,
    [theme.breakpoints.down('sm')]: {
      fontSize: 44,
    },
  },
  imageCentralContext: {
    textAlign: "center",
  },
  imageCentral: {
    width: theme.spacing(80),
    height: theme.spacing(80),
    display: "inline-flex",
  },
  textWhite: {
    color: "white",
  },
  images: {
    marginTop: theme.spacing(10),

    "& > ul": {
      width: "calc(100% + 30px)",
    },
  },
  imageItem: {
    "& .MuiGridListTile-tile": {
      borderRadius: "10px",
    },
  },
  howItWorks: {
    marginTop: theme.spacing(10),
    background:
      "url(https://worc.com.br/worc-empregabilidade-bg-home-como-funciona.66619dc05ca6bf0dbc03.png) no-repeat center",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    textAlign: "center",
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      zoom: "0.7",
    },
  },
  howItWorksTitle: {
    paddingTop: theme.spacing(5),
  },
  howItWorksTitleLogo: {
    paddingTop: theme.spacing(2),
  },
  howItWorksRow1: {
    marginTop: "-140px",
    padding: "0 5%",
    [theme.breakpoints.down("md")]: {
      marginTop: "0",
    },
  },
  howItWorksRow2: {
    paddingTop: theme.spacing(5),
    padding: "0 10%",
    marginTop: "-20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      padding: "0 5%",
    },
  },
  howItWorksRow3: {
    marginTop: "-20px",
    padding: "0 20%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      padding: "0 5%",
    },
  },
  howItWorksRow4: {
    marginTop: "-200px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      padding: "0 5%",
    },
  },
  gray: {
    color: "#888888",
  },
  HowItWorksBallBlue: {
    display: "flex",
    flexDirection: "column",
    width: 320,
  },
  match: {
    background: theme.palette.primary.main,
    flexDirection: "column",
    textAlign: "center",
    paddingTop: 35,
    paddingBottom: 40,
    marginTop: -25,
    "& iframe": {
      marginTop: 20,
    },
  },
  matchAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    background: "white",
    display: "inline-block",
    marginRight: 15,
    marginBottom: 15,
    border: "4px solid #7cf5ab",
    "&:last-of-type": {
      marginRight: 0,
      border: "4px solid transparent",
    },
  },
  faq: {
    background: theme.palette.primary.dark,
    flexDirection: "column",
    textAlign: "center",
    paddingTop: 35,
    paddingBottom: 35,

    "& > button": {
      marginTop: 25,
    },
  },
  footer: {
    width: 100,
    height: 100,
    margin: "15px auto 5px auto",
  },
  link: {
    color: "black",
  },
  avatarContent: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(10),
      textAlign: "center",

      "& .MuiAvatar-root": {
        margin: "0 auto",
      },
    },
  },
  linksContent: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",

      "& button": {
        marginTop: 15,
      },
    },
  },
}))

function CompanyPage({ slug, tracker = null }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [modalEmployeeOpened, setModalEmployeeOpened] = useState(false)
  const { company, loading, findCompanyBySlug } = useCompany()

  const companyImageCentral =
    company?.images?.length && company?.images[0]
      ? company.images[0].image
      : null
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const isMd = useMediaQuery(theme.breakpoints.down("md"))
  const columns = isSm ? 1 : isMd ? 2 : 4

  const goToOpportunity = () => Router.push(`/${slug}/vagas`)

  useEffect(() => {
    if (slug) {
      findCompanyBySlug(slug)
    }
  }, [slug])

  if (loading || !company) {
    return (
      <Container maxWidth="lg" className={classes.rootProgress}>
        <CircularProgress color="primary" />
      </Container>
    )
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Box px={1}>
        <Grid container spacing={2} alignContent="center">
          <Grid item xs={12} sm={2} md={5} className={classes.avatarContent}>
            <Avatar
              alt={company.nome_fantasia || company.razao_social}
              variant="square"
              className={classes.avatar}
              src={company.avatar || company.logo}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={7}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className={classes.linksContent}
            >
              <Link href="https://worc.com.br/home" className={classes.link}>
                Site
              </Link>
              <Link
                href="https://www.facebook.com/PlataformaWorc/"
                className={classes.link}
              >
                Facebook
              </Link>
              <Link
                href="https://www.instagram.com/worc.digital/"
                className={classes.link}
              >
                Instagram
              </Link>
              <Link
                href="https://br.linkedin.com/company/worc-plataforma-de-empregabilidade"
                className={classes.link}
              >
                Linkedin
              </Link>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => goToOpportunity()}
              >
                QUERO TRABALHAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={classes.boxInformation}
        >
          <Grid item xs={12} md={7} className={classes.boxInformationText}>
            <Typography variant="h1" component="h1" className={classes.name}>
              Trabalhe em {company.nome_fantasia || company.razao_social}!
            </Typography>
            <Typography variant="body1">{company.description}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SuccessButton
                  className={classes.boxInformationButtons}
                  onClick={() => goToOpportunity()}
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  QUERO TRABALHAR
                </SuccessButton>
              </Grid>
              <Grid item xs={12} md={6}>
                {!tracker && <Button
                  className={classes.boxInformationButtons}
                  onClick={() => setModalEmployeeOpened(true)}
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  JÁ SOU COLABORADOR
                </Button>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className={classes.imageCentralContext}>
            <Avatar
              alt={company.nome_fantasia || company.razao_social}
              className={classes.imageCentral}
              src={companyImageCentral}
            />
          </Grid>
        </Grid>
        <Grid className={classes.images} container>
          <GridList cellHeight={200} cols={columns} spacing={30}>
            {company.images && company.images.map((img, index) => (
              <GridListTile
                key={ `img-${index}` }
                cols={1}
                className={classes.imageItem}
              >
                <img src={img.image} alt={company.nome_fantasia || company.razao_social} />
              </GridListTile>
            ))}
          </GridList>
        </Grid>
        <Box className={classes.howItWorks}>
          <Typography variant="h3" className={classes.howItWorksTitle}>
            Como funciona?
          </Typography>
          <LogoClean className={classes.howItWorksTitleLogo} />
          <Grid
            container
            justify="space-between"
            className={classes.howItWorksRow1}
          >
            <span style={{ width: 175 }}>
              <Company />
              <Typography variant="h5">Estabelecimento</Typography>
            </span>
            <span style={{ width: 175 }}>
              <Candidate />
              <Typography variant="h5">Profissional</Typography>
            </span>
          </Grid>
          <Grid
            container
            justify="space-between"
            className={classes.howItWorksRow2}
          >
            <HowItWorksBall
              key="ball-1"
              line1="1"
              line2="Faz o cadastro"
              line3="Leva menos de 1 min."
            />
            <HowItWorksBall
              key="ball-2"
              color="success"
              line1="1"
              line2="Faz o cadastro"
              line3="Leva menos de 1 min."
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            className={classes.howItWorksRow3}
          >
            <HowItWorksBall
              key="ball-3"
              line1="2"
              line2="Anuncia uma vaga"
              line3="Leva menos de 5 min."
            />
            <HowItWorksBall
              key="ball-4"
              color="success"
              line1="2"
              line2="Preenche o perfil"
              line3="Quanto mais informações tiver melhor"
            />
          </Grid>
          <Grid container justify="center" className={classes.howItWorksRow4}>
            <Box className={classes.HowItWorksBallBlue}>
              <Typography variant="h5" color="primary">
                3
              </Typography>
              <Typography variant="body2" className={classes.gray}>
                Agora é só deixar o nosso algorítmo trabalhar que fazemos o
                match entre os candidatos mais relevantes e as vagas ideais.
              </Typography>
              <img
                width="320px"
                src="https://worc.com.br/assets/photos/worc-empregabilidade-img-como-funciona.png"
                alt={company.nome_fantasia || company.razao_social}
              />
            </Box>
          </Grid>
        </Box>
        <Box className={classes.match}>
          <Box>
            <Avatar
              src="avatar"
              src={company.avatar || company.logo}
              className={classes.matchAvatar}
            />
            <Avatar
              src="https://worc.com.br/assets/logos/Worc_Logo_Original.svg"
              className={classes.matchAvatar}
            />
          </Box>
          <Typography
            variant="h4"
            className={classes.textWhite}
            gutterBottom
            display="block"
          >
            Match Perfeito
          </Typography>
          <Typography
            variant="h5"
            className={classes.textWhite}
            gutterBottom
            display="block"
          >
            Assista o 1º Episódio da websérie
          </Typography>
          <iframe
            style={{ maxWidth: "100%" }}
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/gzASpKYJGz8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture"
            allowFullScreen
          />
        </Box>
        <Box className={classes.faq}>
          <Faq />
          <SuccessButton
            variant="contained"
            size="large"
            onClick={() => goToOpportunity()}
          >
            QUERO TRABALHAR
          </SuccessButton>
        </Box>
        <Box>
          <Avatar
            src="https://worc.com.br/assets/logos/Worc_Logo_Original.svg"
            className={classes.footer}
          />
        </Box>
      </Box>
      <RegisterCandidateProvider>
        <ModalEmployee
          open={modalEmployeeOpened}
          handleClose={() => setModalEmployeeOpened(false)}
          company={company}
          loading={loading}
        />
      </RegisterCandidateProvider>
    </Container>
  )
}

export default CompanyPage
