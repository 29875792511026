import React, {
  createContext,
  useState,
  useEffect,
  useContext
 } from "react";

 import { service } from '../services';
import { useCompany } from 'hooks/useCompany';

const RegisterCandidate = createContext();

export default function RegisterCandidateProvider({ children }) {
  const [fallBack, setFallBack] = useState({
    error : null,
    success: null
  })
  const [tempToken, setTempToken] = useState(null)
  const [loading, setLoading] = useState(false)
  const { checkIfExistsPreOpportunityUuid } = useCompany()
  const [emailIsValid, setEmailIsValid] = useState({
    status: 400,
    showAlert: false
  })
  const [cpfValidate, setCpfValidate] = useState({
    status: null
  })

  function resetValidate(){
    setCpfValidate({status: null})
  }

  function sendRegisterCandidate(params, images) {
    setLoading(true)
    let formdata = new FormData();

    formdata.append("candidate",params);

    for (let i = 0; i < images.length; i++) {
      formdata.append("images", images[i], images.name);
    }

    let requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    service.registerCandidate.register(requestOptions)
      .then(response => response.json())
      .then(response => {
        setTempToken(response.token)
        checkIfExistsPreOpportunityUuid()
      })
      .catch(error => setFallBack({success: false, error}))
      .finally(() => setLoading(false))
  }

  function checkEmail(email) {
    const check = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if (!check.test(email)){
      return
    }

    setLoading(true)
    service.registerCandidate
      .checkIsValidEmail(email)
      .then(setEmailIsValid)
      .catch(setEmailIsValid)
      .finally(() => setLoading(false))
  }

  function checkCpf(cpf) {
    setLoading(true)
    service.registerCandidate
      .checkIsValidCpf(cpf)
      .then(setCpfValidate)
      .catch(setCpfValidate)
      .finally(() => setLoading(false))
  }

  return (
    <RegisterCandidate.Provider
      value={{
        loading,
        sendRegisterCandidate,
        fallBack,
        tempToken,
        checkEmail,
        emailIsValid,
        checkCpf,
        cpfValidate,
        resetValidate
      }}>
      {children}
    </RegisterCandidate.Provider>
  )
}

export function useRegisterCandidate() {
  const context = useContext(RegisterCandidate);
  const {
    loading,
    tempToken,
    sendRegisterCandidate,
    fallBack,
    checkEmail,
    emailIsValid,
    checkCpf,
    cpfValidate,
    resetValidate
  } = context;

  return {
    loading,
    tempToken,
    sendRegisterCandidate,
    fallBack,
    checkEmail,
    emailIsValid,
    checkCpf,
    cpfValidate,
    resetValidate
   };
}
