import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  Slide,
  Snackbar,
  Typography,
  useTheme,
} from "@material-ui/core"
import StorageIcon from "@material-ui/icons/Storage"
import PublishIcon from "@material-ui/icons/Publish"
import SuccessButton from "components/Button/Success"
import Button from "@material-ui/core/Button"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { red } from "@material-ui/core/colors"
import MaskedInput from "react-maskedinput"
import { cpf } from "cpf-cnpj-validator"
import { service } from "services"
import Alert from '@material-ui/lab/Alert'
import { useCompany } from 'hooks/useCompany'

Yup.addMethod(Yup.string, "cpfValid", function (args) {
  return this.test("test", "CPF inválido", function (value) {
    const {path, message, createError} = this
    return (
      cpf.isValid(value?.toString()?.replace(/[^0-9.]/g, "")) ||
      createError({path, message})
    )
  })
})

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Exo', sans-serif !important",
  },
  content: {
    padding: "8px 24px !important",
    textAlign: "center",
  },
  icon: {
    fontSize: 55,
    margin: "0 auto",
  },
  title: {
    fontWeight: "bold",
  },
  box: {
    marginTop: 15,
    display: "flex",
    alignItems: "center",
    padding: 20,
    flexDirection: "column",
  },
  form: {
    width: "100%",
  },
  image: {
    width: 80,
    height: 80,
  },
  field: {
    fontFamily: "'Exo', sans-serif",
    width: "100%",
    border: "none",
    borderBottom: "2px solid #bdbdbd",
    padding: "6px 0",
    fontSize: 15,
    marginTop: 25,
    outline: "none !important",

    "&:hover, &:focus": {
      borderBottom: `2px solid ${theme.palette.primary.light}`,
    },

    "&+span": {
      marginTop: 5,
      display: "inline-block",
      color: red[600],
    },
  },
  buttons: {
    marginTop: 25,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ModalEmployee({open, handleClose, company, loading}) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [fileInput, setFileInput] = useState()
  const [fileImage, setFileImage] = useState()
  const [positions, setPositions] = useState()
  const {
    error,
    setErrorModalOpened,
    setSuccessModalOpened,
    registerEmployee,
    errorModalOpened,
    successModalOpened,
  } = useCompany()

  const fileSelectedHandler = (setFieldValue, event) => {
    setFileImage(URL.createObjectURL(event.target.files[0]))
    setFieldValue("file", event.target.files)
  }

  const clearForm = (setFieldValue) => {
    setFileImage(null)
    setFieldValue("file", null)
    setFieldValue("name", "")
    setFieldValue("email", "")
    setFieldValue("password", "")
    setFieldValue("phone", "")
    setFieldValue("cpf", "")
    setFieldValue("type_opportunity", "")
    setFieldValue("position", "")
    setFieldValue("subsidiary_id", '')
  }

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      positions: [parseInt(values.position)],
      subsidiary_id: parseInt(values.subsidiary_id),
      freelancer: values.type_opportunity === 'freelancer',
      available_to_work: false,
      position: undefined,
      file: undefined,
    }

    registerEmployee(JSON.stringify(payload), values.file)
  }

  const handleCloseErrorModal = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setErrorModalOpened(false)
  }

  const handleCloseSuccessModal = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSuccessModalOpened(false)
  }

  useEffect(() => {
    service.jobPosition.list().then(setPositions).catch(console.error)
  }, [])

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent className={classes.content}>
        <StorageIcon className={classes.icon} color="primary"/>
        <Typography
          gutterBottom
          variant="h4"
          display="block"
          color="primary"
          className={classes.title}
        >
          Dados do colaborador
        </Typography>
        <Divider/>
        <Box className={classes.box}>
          <Formik
            initialValues={{
              file: null,
              name: "",
              email: "",
              password: "",
              phone: "",
              cpf: "",
              type_opportunity: '',
              position: '',
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required("É um campo obrigatório")
                .min(2, "Um pouco pequeno"),
              email: Yup.string()
                .email("Precisa ser um email valido")
                .required("É um campo obrigatório"),
              password: Yup.string()
                .required("É um campo obrigatório")
                .min(6, "minimo de 6"),
              cpf: Yup.string().required("É um campo obrigatório").cpfValid(),
              phone: Yup.string().required("É um campo obrigatório"),
              type_opportunity: Yup.string()
                .oneOf(["clt", "clt_flex", "freelancer"])
                .required("É um campo obrigatório"),
              position: Yup.string()
                .ensure()
                .required("É um campo obrigatório"),
              subsidiary_id: Yup.string()
                .ensure()
                .required("É um campo obrigatório"),
            })}
            onSubmit={handleSubmit}
          >
            {({setFieldValue}) => (
              <Form autoComplete="off" className={classes.form}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5} align="right">
                    <Avatar src={fileImage} className={classes.image}/>
                  </Grid>
                  <Grid item xs={7} align="left">
                    <input
                      type="file"
                      onChange={(event) =>
                        fileSelectedHandler(setFieldValue, event)
                      }
                      style={{display: "none"}}
                      ref={(fileInput) => setFileInput(fileInput)}
                      accept=".jpg, .jpeg, .png"
                      id="file"
                      name="file"
                    />
                    <SuccessButton
                      className={classes.imageUpload}
                      type="button"
                      onClick={() => fileInput.click()}
                    >
                      <PublishIcon/>
                      incluir Imagem
                    </SuccessButton>
                  </Grid>
                </Grid>
                <Box>
                  <Field
                    className={classes.field}
                    name="name"
                    type="text"
                    placeholder="* Nome"
                    autoComplete="off"
                    maxLength={80}
                  />
                  <ErrorMessage component="span" name="name"/>
                </Box>
                <Box>
                  <Field
                    className={classes.field}
                    name="email"
                    type="email"
                    placeholder="* E-mail"
                    autoComplete="off"
                    maxLength={80}
                  />
                  <ErrorMessage component="span" name="email"/>
                </Box>
                <Box>
                  <Field
                    className={classes.field}
                    name="password"
                    type="password"
                    placeholder="* Senha"
                    autoComplete="off"
                    maxLength={20}
                  />
                  <ErrorMessage component="span" name="password"/>
                </Box>
                <Box>
                  <Field
                    name="phone"
                    type="text"
                    render={({field}) => (
                      <MaskedInput
                        {...field}
                        className={classes.field}
                        type="text"
                        placeholder="* Telefone"
                        mask="(11)11111-1111"
                        onChange={(e) => setFieldValue("phone", e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="phone"/>
                </Box>
                <Box>
                  <Field
                    name="cpf"
                    type="text"
                    render={({field}) => (
                      <MaskedInput
                        {...field}
                        className={classes.field}
                        type="text"
                        placeholder="* CPF"
                        mask="111.111.111-11"
                        onChange={(e) => setFieldValue("cpf", e.target.value)}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="cpf"/>
                </Box>
                <Box>
                  <Field
                    className={classes.field}
                    name="type_opportunity"
                    as="select"
                    onChange={(e) =>
                      setFieldValue("type_opportunity", e.target.value)
                    }
                  >
                    <option value="">* Tipo da vaga</option>
                    <option value="clt">CLT</option>
                    {/* <option value="clt_flex">CLT Flex</option> */}
                    <option value="freelancer">Freelancer</option>
                  </Field>
                  <ErrorMessage component="span" name="type_opportunity"/>
                </Box>
                <Box>
                  <Field
                    className={classes.field}
                    name="position"
                    as="select"
                    onChange={(e) => setFieldValue("position", e.target.value)}
                  >
                    <option value="">* Cargo</option>
                    {positions?.map((position) => (
                      <option value={position.id}>{position.name}</option>
                    ))}
                  </Field>
                  <ErrorMessage component="span" name="position"/>
                </Box>
                <Box>
                  <Field
                    className={classes.field}
                    name="subsidiary_id"
                    as="select"
                    onChange={(e) => setFieldValue("subsidiary_id", e.target.value)}
                  >
                    <option value="">* Filial</option>
                    {company.address?.map((address) => (
                      <option value={address.subsidiary_id}>{address.name}</option>
                    ))}
                  </Field>
                  <ErrorMessage component="span" name="subsidiary_id"/>
                </Box>
                <Grid container spacing={2} className={classes.buttons}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="large"
                      color="primary"
                      variant="outlined"
                      onClick={() => clearForm(setFieldValue)}
                    >
                      Limpar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      fullWidth
                      size="large"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                    >
                      Concluir
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
      {<Snackbar open={errorModalOpened} autoHideDuration={6000} onClose={handleCloseErrorModal}>
        <Alert severity="error" onClose={handleCloseErrorModal}>
          {error || 'Desculpe! Ocorreram erros ao finalizar seu cadastro, tente novamente ou entre em contato com o suporte.'}
        </Alert>
      </Snackbar>}
      {<Snackbar open={successModalOpened} autoHideDuration={6000} onClose={handleCloseSuccessModal}>
        <Alert severity="success" onClose={handleCloseSuccessModal}>
          Cadastro efetuado com sucesso! Você será redirecionado em breve!
        </Alert>
      </Snackbar>}
    </Dialog>
  )
}
