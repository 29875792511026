const faqs = [
  {
    title: 'Como funciona?',
    description: 'A Worc é uma plataforma de empregabilidade que te conecta com seu próximo emprego. Ao fazer seu cadastro no nosso site, como freelancer ou fixo, os bares e restaurantes cadastrados terão como encontrar o seu perfil quando forem buscar uma vaga. Assim, se você for escolhido, você receberá um WhatsApp para confirmar uma entrevista na plataforma. Depois da entrevista, pedimos um feedback seu e o dono do estabelecimento. E então, depende do estabelecimento seguir, ou não, com a contratação! E pronto, você estará empregado em um dos maiores bares e restaurantes do Brasil!',
  },
  {
    title: 'Quais empresas?',
    description: 'Nossos clientes são diversos restaurantes, bares, mercados e empresas que contratam serviços terceirizados.',
  },
  {
    title: 'Como me cadastro?',
    description: 'É só entrar no www.worc.com.br e colocar suas informações pessoais, como nome, endereço e preencher com suas experiências e pretensões profissionais. Não se esqueça que assim que colocar seus dados fazer os testes da sua área e anexar seu currículo.',
  },
  {
    title: 'O que devo colocar no perfil?',
    description: 'Suas informações pessoais, experiências profissionais ou se é seu primeiro trabalho e anexar o seu currículo! Além disso, você pode assistir aos cursos grátis e completar os testes, para ter mais pontuação no ranking e provar sua preparação e capacitação para o estabelecimento.',
  },
  {
    title: 'Por que colocar foto?',
    description: 'Quanto mais completo o perfil, maiores são as chances de contratação. Colocar a foto aumenta em até 33x!',
  },
  {
    title: 'Por que colocar endereço?',
    description: 'Porque o estabelecimento precisa saber onde você mora, antes de querer te contratar. Esse é um dos pontos mais importantes!',
  },
  {
    title: 'Por que devo anexar currículo?',
    description: 'Anexando o seu currículo, o dono de estabelecimento consegue ver mais fácil e rápido informações sobre você e sobre suas experiências, além de passar um ar maior de responsabilidade. Lembre-se de colocar funções exercidas nos cargos anteriores no seu currículo.',
  },
  {
    title: 'O que é essa sessão "sobre mim"?',
    description: 'É um espaço livre para você se destacar ao dono do estabelecimento que estiver procurando. Nele, te aconselhamos a escrever 3 linhas com suas principais características e porque faria a diferença em uma equipe!',
  },
  {
    title: 'Por que devo colocar minhas experiências profissionais?',
    description: 'Colocar experiência é essencial pois muitos estabelecimentos precisam de funcionários com experiências antigas, e até gostam de falar com ex-patrões para pegar referências.',
  },
  {
    title: 'Quantas posições devo colocar?',
    description: 'Você pode escolher até 3 posições de seu interesse. Lembre de colocar se já teve experiência em cada uma delas e, se sim, quanto tempo.',
  },
  {
    title: 'Como funciona o contato com a Worc?',
    description: 'Se precisar entrar em contato com algum funcionário da Worc, é só você mandar uma mensagem no Whatsapp no número (11) 99439-9159. Logo que você mandar a primeira mensagem você receberá esta mensagem padrão do nosso robô que pede para você escolher um departamento. Assim que você selecionar um dos números, você entrará em contato com algum de nossos funcionários que você pode tirar suas dúvidas, saber mais informações sobre vagas e nos relatar caso tenha algum problema no site. Este filtro foi criado para poder filtrar o tema e assim te atender melhor',
  },
  {
    title: 'Onde fica a entrevista? E local de trabalho?',
    description: 'Depende do estabelecimento. Tudo isso será informado assim que agendarmos uma entrevista.',
  },
  {
    title: 'Quantos dias espero?',
    description: 'Depende do local de trabalho. Entraremos em contato com você para agendar uma entrevista.',
  },
  {
    title: 'O que preciso para a entrevista?',
    description: 'Documento com foto e currículo impresso. Caso precise levar algo extra, avisaremos.',
  },
  {
    title: 'O que a Worc te oferece?',
    description: 'Oferecemos oportunidade de emprego, além da capacitação e preparação para ela, e acompanhamento da manutenção de você como funcionário.',
  },
  {
    title: 'Quais são as provas?',
    description: 'Temos uma prova de perfil profissional, com perguntas que validam seu bom psicológico ao estabelecimento, além de provas relacionadas a todas as vagas que oferecemos!',
  },
  {
    title: 'Posso fazer de novo a prova?',
    description: 'Pode sim, depois de 30 dias! Aproveite esse tempo para fazer nossos cursos e aprender mais sobre o tema!',
  },
  {
    title: 'O que é o Worc Educação?',
    description: 'A o espaço de aprendizagem da Worc. Dentro dela estão disponíveis diversos cursos em formato de vídeo, com dicas dos principais profissionais do mercado, para que você possa aprender um pouco mais sobre a área!',
  },
  {
    title: 'Deu problema! O que fazer?',
    description: 'Não se preocupe! Só chamar no Whatsapp (clicando no botão do ícone embaixo do site), mandar um print e nos informar seu modelo de celular! Vamos repassar para a equipe de tecnologia e te ajudar o mais rápido que conseguirmos.',
  },
]

export default faqs
