import React from 'react';

export default function LogoClean(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="120.061" height="120.58" viewBox="0 0 120.061 120.58" {...props}>
        <g id="Grupo_1381" data-name="Grupo 1381" transform="translate(-960.093 -3786.667)">
          <g id="Grupo_1380" data-name="Grupo 1380">
            <path id="Caminho_442" data-name="Caminho 442" d="M0,0A90.239,90.239,0,0,1,90.061,90.58" transform="translate(960.093 3816.667)" fill="none" stroke="#41c0f0" strokeWidth="60"/>
            <path id="Caminho_443" data-name="Caminho 443" d="M44.99,45.249A45.079,45.079,0,0,0,0,0V45.249Z" transform="translate(960.133 3861.987)" fill="#41c0f0"/>
          </g>
        </g>
      </svg>
    );
}
