import { Accordion, AccordionDetails, Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import faqs from 'containers/company/utils/faq'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
  },
  item: {
    margin: '15px 45px !important',
    borderRadius: 10,
  },
  title: {
    padding: '15px 10px',
    fontWeight: '100',
  },
  description: {
    padding: '0 10px',
    textAlign: 'left',
  },
  faq: {
    marginLeft: 45,
    marginBottom: 40,

    '& svg': {
      marginTop: -12,
      verticalAlign: 'middle',
    }
  },
}))

export default function HowItWorksBall() {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Box className={classes.root}>
      <Typography variant="h3" gutterBottom color="primary" className={classes.faq}>
        <HelpOutlineIcon color="primary" fontSize="inherit" />
        Perguntas frequentes
      </Typography>
      {faqs.map((faq, i) => (
        <Accordion className={classes.item} key={`${i}-key-${classes.title}`}>
          <AccordionSummary
            expandIcon={<AddCircleOutlineIcon fontSize="large" color="primary" />}
            aria-controls={`${i}-content-${classes.title}`}
            id={`${i}-header-${classes.title}`}
          >
            <Typography variant="h5" className={classes.title}>{faq.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              {faq.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}
