import { useRouter } from 'next/router'
import { ThemeProvider } from '@material-ui/core/styles'
import candidateTheme from 'providers/theme/mui4/candidate'
import Cookies from 'js-cookie'
import CompanyPage from './company-page'

function CompanyContainer() {
  const router = useRouter()
  const {
    query: { slug },
    asPath,
  } = router
  const trackerCookie = Cookies.get('tracker')
  const hashParam = asPath.split('#')
  const tracker = hashParam && hashParam?.length > 1 ? hashParam[hashParam.length - 1] : null

  if (tracker) {
    Cookies.set('tracker', tracker)
    router.push(asPath.replace(`#${tracker}`, ''))
  }

  return (
    <ThemeProvider theme={candidateTheme}>
      <CompanyPage slug={slug} tracker={trackerCookie} />
    </ThemeProvider>
  )
}

export default CompanyContainer
