export default function Candidate(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="78.271" height="123.612" viewBox="0 0 78.271 123.612" {...props}>
        <g id="chef_1_" data-name="chef (1)" transform="translate(-93.9)">
          <g id="Grupo_987" data-name="Grupo 987" transform="translate(93.9 74.177)">
            <g id="Grupo_986" data-name="Grupo 986" transform="translate(0 0)">
              <path id="Caminho_438" data-name="Caminho 438" d="M159.94,309.777l-16.292-2.515a2.066,2.066,0,0,0-1.376.271,17.9,17.9,0,0,1-18.474,0,2.051,2.051,0,0,0-1.376-.271l-16.292,2.515A14.339,14.339,0,0,0,93.9,324.026v32.647h4.12V324.026a10.238,10.238,0,0,1,8.736-10.177l15.577-2.4a21.993,21.993,0,0,0,21.4,0l15.577,2.4a10.238,10.238,0,0,1,8.736,10.177v32.647h4.122V324.026a14.339,14.339,0,0,0-12.231-14.249Z" transform="translate(-93.9 -307.238)" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_989" data-name="Grupo 989" transform="translate(106.256)">
            <g id="Grupo_988" data-name="Grupo 988" transform="translate(0)">
              <path id="Caminho_439" data-name="Caminho 439" d="M188.341.027A10.291,10.291,0,0,0,180.1,4.152a10.288,10.288,0,0,0-16.469-.011A10.284,10.284,0,0,0,155.42,0h-.035a10.3,10.3,0,0,0-4.12,19.739V39.162a2.061,2.061,0,0,0,2.06,2.06H190.4a2.06,2.06,0,0,0,2.06-2.066V19.737a10.307,10.307,0,0,0,6.179-9.4v-.007a10.31,10.31,0,0,0-10.3-10.3Zm1.544,16.256a2.061,2.061,0,0,0-1.545,1.994V37.1H155.386V18.274a2.06,2.06,0,0,0-1.545-1.994,6.18,6.18,0,0,1,1.544-12.163h.021a6.186,6.186,0,0,1,6.159,6.175v.033a2.06,2.06,0,0,0,2.058,2.06h0a2.061,2.061,0,0,0,2.062-2.052V10.3a6.179,6.179,0,1,1,12.358.021,2.06,2.06,0,1,0,4.119,0,6.179,6.179,0,1,1,7.724,5.957Z" transform="translate(-145.086)" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_991" data-name="Grupo 991" transform="translate(112.435 37.103)">
            <g id="Grupo_990" data-name="Grupo 990">
              <path id="Caminho_440" data-name="Caminho 440" d="M209.816,153.678H172.741a2.061,2.061,0,0,0-2.06,2.06V168.1a20.6,20.6,0,0,0,41.2,0V155.738a2.059,2.059,0,0,0-2.06-2.06Zm-2.06,14.418a16.478,16.478,0,1,1-32.956,0V157.8h32.956Z" transform="translate(-170.681 -153.678)" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_993" data-name="Grupo 993" transform="translate(120.682 65.933)">
            <g id="Grupo_992" data-name="Grupo 992">
              <rect id="Retângulo_408" data-name="Retângulo 408" width="4.12" height="10.299" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_995" data-name="Grupo 995" transform="translate(141.281 65.933)">
            <g id="Grupo_994" data-name="Grupo 994">
              <rect id="Retângulo_409" data-name="Retângulo 409" width="4.12" height="10.299" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_997" data-name="Grupo 997" transform="translate(108.317 77.801)">
            <g id="Grupo_996" data-name="Grupo 996" transform="translate(0)">
              <path id="Caminho_441" data-name="Caminho 441" d="M182.517,322.251l-1.728,6.909-25.423,3.911a2.06,2.06,0,0,0-1.747,2.035v32.956h4.116V336.876l25.03-3.851a2.059,2.059,0,0,0,1.683-1.537l2.06-8.239Z" transform="translate(-153.618 -322.251)" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_999" data-name="Grupo 999" transform="translate(120.682 107.127)">
            <g id="Grupo_998" data-name="Grupo 998">
              <rect id="Retângulo_410" data-name="Retângulo 410" width="4.12" height="4.12" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1001" data-name="Grupo 1001" transform="translate(120.682 94.769)">
            <g id="Grupo_1000" data-name="Grupo 1000">
              <rect id="Retângulo_411" data-name="Retângulo 411" width="4.12" height="4.12" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1003" data-name="Grupo 1003" transform="translate(120.682 119.486)">
            <g id="Grupo_1002" data-name="Grupo 1002">
              <rect id="Retângulo_412" data-name="Retângulo 412" width="4.12" height="4.12" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1005" data-name="Grupo 1005" transform="translate(141.281 107.127)">
            <g id="Grupo_1004" data-name="Grupo 1004">
              <rect id="Retângulo_413" data-name="Retângulo 413" width="4.12" height="4.12" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1007" data-name="Grupo 1007" transform="translate(141.281 94.769)">
            <g id="Grupo_1006" data-name="Grupo 1006">
              <rect id="Retângulo_414" data-name="Retângulo 414" width="4.12" height="4.12" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1009" data-name="Grupo 1009" transform="translate(141.281 119.486)">
            <g id="Grupo_1008" data-name="Grupo 1008">
              <rect id="Retângulo_415" data-name="Retângulo 415" width="4.12" height="4.12" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1011" data-name="Grupo 1011" transform="translate(153.639 92.709)">
            <g id="Grupo_1010" data-name="Grupo 1010">
              <rect id="Retângulo_416" data-name="Retângulo 416" width="4.12" height="30.896" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1013" data-name="Grupo 1013" transform="translate(120.682 28.857)">
            <g id="Grupo_1012" data-name="Grupo 1012">
              <rect id="Retângulo_417" data-name="Retângulo 417" width="30.896" height="4.12" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1015" data-name="Grupo 1015" transform="translate(137.161 22.678)">
            <g id="Grupo_1014" data-name="Grupo 1014">
              <rect id="Retângulo_418" data-name="Retângulo 418" width="4.12" height="8.239" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1017" data-name="Grupo 1017" transform="translate(124.802 22.678)">
            <g id="Grupo_1016" data-name="Grupo 1016">
              <rect id="Retângulo_419" data-name="Retângulo 419" width="4.12" height="8.239" fill="#3bfbaa"/>
            </g>
          </g>
          <g id="Grupo_1019" data-name="Grupo 1019" transform="translate(124.847 77.792)">
            <g id="Grupo_1018" data-name="Grupo 1018" transform="translate(0 0)">
              <rect id="Retângulo_420" data-name="Retângulo 420" width="4.119" height="8.492" transform="translate(0 0.999) rotate(-14.035)" fill="#3bfbaa"/>
            </g>
          </g>
        </g>
      </svg>
    );
}
